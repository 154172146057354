<template>
  <draggable
    v-model="dashboardSort.section_3"
    class="section-three"
    group="table"
    :class="isDisable ? 'section-three-in-active' : ''"
    :style="{ flex: isSectionOne_Two && 1 }"
    :disabled="isDisable"
    @change="updateOrder"
  >
    <template v-for="(row, i) in dashboardSort?.section_3">
      <template v-if="row.card_name == 'Map'">
        <v-card
          v-if="(options.tenant_id || options.fleetId) && showMap"
          :key="i"
          :loading="loading"
          class="py-1 px-1"
        >
          <DashboardMap
            v-if="(options.tenant_id || options.fleetId) && showMap"
            :channel-name="dashboadData.tenant_channel_name"
            :product="options.product"
          />
        </v-card>
      </template>
      <template v-else-if="row?.keys[0]">
        <v-card
          :key="i"
          :loading="loading"
        >
          <Top5
            :disbaled="!isDisable"
            :loading="loading"
            :row="row"
            :items="dashboadData[row.keys[0]]"
          />
        </v-card>
      </template>
    </template>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  components: {
    draggable,
    DashboardMap: () => import("../utils/DashboardMap.vue"),
    Top5: () => import("../utils/Top5.vue"),
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    vehicleTech: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      showMap: true,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "dashboard/getIsLoading",
      dashboadData: "dashboard/getDashboadData",
      dashboardSort: "dashboard/getDashboadSortData",
      //
      selectedCards: "dashboard/getSelectedCards",
      //
      vehicleList: "vehicles/getVehiclesList",
    }),
    isSectionOne_Two() {
      return !this.dashboardSort?.section_1 && !this.dashboardSort?.section_2;
    },
    isDisable() {
      return this.selectedCards.length == 0 ? true : false;
    },
    checkTenant() {
      return this.options.is_independent_fleet
        ? this.options.fleetId
        : this.options.tenant_id;
    },
  },
  watch: {
    vehicleList: {
      handler: function () {
        this.showMap = false;
        setTimeout(() => {
          this.showMap = true;
        }, 100);
      },
    },
  },
  methods: {
    updateOrder() {
      const params = {
        section: "section_3",
        sort: true,
        item: this.dashboardSort.section_3,
        vehicle_tech: this.vehicleTech,
        // from: val.moved.oldIndex,
        // to: val.moved.newIndex,
      };

      this.$store.dispatch("dashboard/updateCard", params);
    },
  },
};
</script>
<style lang="sass" scoped>

.section-two-in-active
  .v-card
    cursor: auto !important

.section-three
  width: 35%
  .v-card
    cursor: all-scroll
    background: transparent !important

@media only screen and (max-width: 960px)
  .section-three
    width: 100%
</style>
